import React from 'react';
import * as Sentry from '@sentry/nextjs';
import Container from '@mui/material/Container';
import ErrorPage from './error-page';

const Page: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Sentry.ErrorBoundary
    fallback={
      <Container maxWidth="md" sx={{ my: 4 }}>
        <ErrorPage
          title="service temporarily unavailable"
          text="// no panic! our servers got tired, but will be back on track soon. working on it 🙌"
        />
      </Container>
    }
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default Page;
