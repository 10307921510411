import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';
import Routes from 'Config/routes';
import AppConfig from 'Config/app';

import Link from 'Components/common/link';
import Page from 'Components/common/page';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Image from 'next/image';
import MagicUnicorn from '@ui-lib/images/magic-unicorn.svg';

const Home: NextPage = () => (
  <Page>
    <Head>
      <title>{AppConfig.Title}</title>
    </Head>
    {process.env.NODE_ENV === 'development' && (
      <Stack spacing={2}>
        <Link href={Routes.About}>Components list (only dev link)</Link>
        <Link href={`${Routes.Experience}/82694a42135bcc4f`}>
          Experience with token (only dev link)
        </Link>
      </Stack>
    )}
    <Box sx={{ textAlign: 'center' }}>
      <Box mb={6}>
        <Image src={MagicUnicorn as string} alt="Unicorn" />
      </Box>
      <Typography variant="h1">IT&apos;S NOTHING HERE</Typography>
      <Typography>
        <Typography color="textSecondary" component="span">
          For real
        </Typography>{' '}
        👁👁
      </Typography>
    </Box>
  </Page>
);

export default Home;
