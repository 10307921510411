import React, { ReactNode } from 'react';
import Image from 'next/image';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ErrorImg from '@ui-lib/images/error-page-img.svg';

type ErrorPageProps = {
  title: string;
  text: string;
  button?: ReactNode;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  title,
  text,
  button,
}: ErrorPageProps) => (
  <Box
    sx={{
      display: 'grid',
      gap: 2,
      gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' },
    }}
  >
    <Box>
      <Typography
        variant="h1"
        component="h1"
        gutterBottom
        textTransform="uppercase"
      >
        {title}
      </Typography>
      <Typography variant="caption" component="p" gutterBottom>
        {text}
      </Typography>
      {button}
    </Box>
    <Box sx={{ textAlign: { sm: 'right', xs: 'center' } }}>
      <Image src={ErrorImg as string} alt="Error" />
    </Box>
  </Box>
);

export default ErrorPage;
